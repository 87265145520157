@keyframes SGS0fW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes SGS0fW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.SGS0fW_multilineInput > :not(:first-child) {
  margin-top: 8px;
}

.SGS0fW_multilineInput .SGS0fW_firstFieldWithMultiInputs {
  padding-right: 36px;
}

.SGS0fW_multilineInput .SGS0fW_deletableInput {
  align-items: center;
  display: flex;
}

.SGS0fW_multilineInput .SGS0fW_deletableInput > :first-child {
  flex: 1;
}

.SGS0fW_multilineInput .SGS0fW_deletableInput > :not(:first-child) {
  margin-left: 8px;
}

.SGS0fW_multilineInput .SGS0fW_minusIcon {
  color: var(--color-text-secondary);
}

.SGS0fW_multilineInput .SGS0fW_addAnother {
  margin-top: 4px;
}

.SGS0fW_multilineInput .SGS0fW_error {
  font: var(--font-body-2);
  color: var(--color-error);
  margin-top: 4px;
}

@keyframes _5hpe_G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _5hpe_G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._5hpe_G_headlineWithMultiInputs {
  padding-right: 36px;
}

@keyframes _3IKyoq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _3IKyoq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._3IKyoq_wrapper {
  align-items: flex-start;
  display: flex;
  position: relative;
}

._3IKyoq_wrapper ._3IKyoq_field, ._3IKyoq_wrapper ._3IKyoq_field ._3IKyoq_multiTextInput {
  flex: 1;
}

._3IKyoq_wrapper ._3IKyoq_saveButton {
  flex-shrink: 0;
  margin: 24px 0 0 8px;
}

form {
  margin: 16px 0;
}

@keyframes RdP3iG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes RdP3iG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.RdP3iG_container {
  width: 100%;
  margin-top: 12px;
}

.RdP3iG_container ul {
  border-bottom: 1px solid var(--color-divider);
  margin: 4px 0;
  padding: 0;
  display: flex;
}

.RdP3iG_container ul li {
  font: var(--font-label-2);
  color: var(--color-text-secondary);
  margin-right: 24px;
  padding-bottom: 4px;
  cursor: pointer;
  margin-block-end: unset;
  padding-inline-start: unset;
  list-style: none;
}

.RdP3iG_container ul li[aria-selected="true"] {
  color: var(--color-text-link);
  border-bottom: 2px solid var(--color-text-link);
  outline: none;
  margin-bottom: -1px;
}

.RdP3iG_container .RdP3iG_hidden {
  display: none;
}

@keyframes yaoX-W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes yaoX-W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.yaoX-W_sample {
  border: 0 solid var(--color-divider);
  background: var(--color-surface-variant);
  border-radius: 12px;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  padding: 16px;
  display: flex;
}

.yaoX-W_sample hgroup header {
  color: var(--color-neutral-10);
  font: var(--font-label-2);
}

.yaoX-W_sample hgroup p {
  color: var(--color-neutral-50);
  font: var(--font-body-3);
  margin-top: 4px;
}

.yaoX-W_logo {
  width: 48px;
  height: 48px;
}

@keyframes wjiRLq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes wjiRLq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.wjiRLq_container {
  width: 28px;
  height: 28px;
  color: var(--color-text-link);
  background: var(--color-focused-variant);
  font: var(--font-title-2);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.wjiRLq_container.wjiRLq_active {
  color: var(--color-on-primary);
  background: var(--color-primary);
}

.wjiRLq_container.wjiRLq_completed {
  background: var(--color-primary);
}

.wjiRLq_container.wjiRLq_completed > svg {
  color: var(--color-on-primary);
}

@keyframes cmGqCa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes cmGqCa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.cmGqCa_wrapper {
  background: var(--color-layer-1);
  width: 100%;
  border-radius: 16px;
  padding: 20px 24px;
}

.cmGqCa_wrapper header {
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
  display: flex;
}

@keyframes aKLBmq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes aKLBmq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.aKLBmq_wrapper {
  position: relative;
}

.aKLBmq_fullWidth {
  width: 100%;
}

.aKLBmq_navigationAnchor {
  position: absolute;
  inset: 0 auto 0 0;
  transform: translateX(-100%);
}

.aKLBmq_navigation {
  width: 220px;
  flex-shrink: 0;
  margin-right: 16px;
  position: sticky;
  top: 0;
}

.aKLBmq_navigation > :not(:last-child) {
  margin-bottom: 24px;
}

.aKLBmq_content {
  max-width: 858px;
}

.aKLBmq_content > :not(:last-child) {
  margin-bottom: 24px;
}

.aKLBmq_stepper {
  font: var(--font-title-2);
  color: var(--color-text);
  border: 1px solid var(--color-surface-5);
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 16px;
}

.aKLBmq_stepper:hover {
  background: var(--color-surface-5);
}

.aKLBmq_stepper.aKLBmq_active {
  background: var(--color-focused-variant);
}

/*# sourceMappingURL=README.9cf8fb1e.css.map */
