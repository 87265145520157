@use 'src/scss/underscore' as _;

.wrapper {
  display: flex;
  align-items: flex-start;
  position: relative;

  .field {
    flex: 1;

    .multiTextInput {
      flex: 1;
    }
  }

  .saveButton {
    flex-shrink: 0;
    margin: _.unit(6) 0 0 _.unit(2);
  }
}

form {
  margin: _.unit(4) 0;
}
