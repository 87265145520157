@use 'src/scss/underscore' as _;

.sample {
  display: flex;
  padding: _.unit(4);
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  border: 0 solid var(--color-divider);
  background: var(--color-surface-variant);

  hgroup {
    header {
      color: var(--color-neutral-10);
      font: var(--font-label-2);
    }

    p {
      margin-top: _.unit(1);
      color: var(--color-neutral-50);
      font: var(--font-body-3);
    }
  }
}

.logo {
  width: 48px;
  height: 48px;
}
